
import { defineComponent, ref, computed, reactive, onMounted, watchEffect, getCurrentInstance, onBeforeMount } from 'vue'
import { store } from "@/store";
import { FormInstance } from 'element-plus';
import Customers from '@/core/services/Callable/Customers';
import Validations from '@/core/services/etc/Validations';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import router from '@/router/clean';
import { etc, rule, notification } from '@/store/stateless';
import Invoice from '@/core/services/Callable/Invoice';
import Etcs from '@/core/services/Callable/Etcs';
import { InvoiceMutationTypes } from '@/store/enums/Invoice/Mutations';
// import { invoiceDefault } from "@/core/utils/invoice.js";
import { ElLoading } from 'element-plus'
import Quote from '@/core/services/Callable/Quote';

export default defineComponent({
    setup() {
        const ruleFormRef = ref<FormInstance>()

        const payment_warning = ref()
        const invoiceDefault = ref({
            invoice_id: null,
            quote_ref_no:null,
            customer_id: null,
            lead_id: null,
            insurance_plan: null,
            copay: null,
            orginal_policy_price: 0,
            orginal_policy_price_vat: 0,
            policy_price: 0,
            invoice_amount:0,
            sales_price: 0,
            basmah:0,
            basmah_vat:0,
            is_discount_given: false,
            discount_amount: 0,
            is_split_payment: false,
            payment_type: null,
            pending_payment_type: null,
            installments: null,
            order_description: null,
            reference_no: null,
            payment_date: null,
            file_receipt: null,
            language: "en",
            expiry_date_time: null,
            client_classification:null,
            members: null,
            icp: 0,
            icp_vat: 0,
            non_taxable_fee:0

        })

        const invoiceModalRef = ref();
        const hasPendingInvoice = ref()

        const health = computed(() => {
            return store.state.customer.health;
        })

        const loading = computed(() => {
            return store.state.customer.detailLoading
        })

        const clickLoading = computed(() => {
            return store.state.invoice.loading
        })

        const invoice = computed(() => {
            return store.state.invoice.invoice;
        })

        const openModal = computed(() => {
            return store.state.invoice.openInvoiceModal;
        });

        const isError = ref<boolean>(false);

        const closeModal = (e) => {
            store.commit(InvoiceMutationTypes.SET_INVOICE_MODAL,false);
        };


        const rules = reactive({
            // insurance_type: [{ validator: Validations.insurance_type, trigger: ['blur'] }],
            insurance_plan: [{ validator: Validations.insurance_plan, trigger: ['blur', 'change'] }],
            orginal_policy_price: [{ validator: Validations.policy_price, trigger: ['blur', 'change'] }],
            invoice_amount: [{ validator: Validations.invoice_amount, trigger: ['blur', 'change'] }],
            sales_price: [{ validator: Validations.sales_price, trigger: ['blur', 'change'] }],
            client_classification: [{ validator: Validations.client_classification, trigger: ['blur', 'change'] }],
            discount_amount: [{ validator: Validations.discount_amount, trigger: ['blur', 'change'] }],
            payment_type: [{ validator: Validations.payment_type, trigger: ['blur', 'change'] }],
            pending_payment_type: [{ validator: Validations.payment_type, trigger: ['blur', 'change'] }],
            // order_description: [{ validator: Validations.order_description, trigger: ['blur', 'change'] }],
            reference_no: [{ validator: Validations.reference_no, trigger: ['blur', 'change'] }],
            payment_date: [{ validator: Validations.payment_date, trigger: ['blur', 'change'] }],
            installments: [{ validator: Validations.installments, trigger: ['blur', 'change'] }],
            file_receipt: [{ validator: Validations.file_receipt, trigger: ['blur', 'change'] }],
            expiry_date_time: [{ validator: Validations.expiry_date_time, trigger: ['blur', 'change'] }],
        })

        let Receipt: any;
        let chequeReceipt: any;

        const handleFileUpload = (file) => {
            // formData = new FormData();
            // rules.file_data.show = false;
            // file = event.target.files[0];
            // if (file == "" && addInvoiceBodyParam.value.payment_type != "1") {
            //     rules.value.file_data.show = false;
            //     isFormValid.value = false;
            // }
            //

            Receipt = file;
            // console.log('file',file);
        };

        const handleChequeFileUpload = (file) => {
            chequeReceipt = file;
        }

        const revalidate = () => {
            if(invoice.value.payment_type == '3'){
                ruleFormRef.value?.clearValidate('order_description')
            }
            
            if(invoice.value.file_receipt){
                ruleFormRef.value?.clearValidate('file_receipt')
            }
            if (isSubmitted.value) {
                ruleFormRef.value?.validate((valid) => {
                    if (!valid) {
                        // 
                    }
                })
            }
        }

        watchEffect(() => {
            if(health.value && health.value.has_pending_invoice && hasPendingInvoice.value) {
                hasPendingInvoice.value.open(true)
            }
        })

        const isSubmitted = ref(false)
        const top = ref()
        const middle = ref()
        const last = ref()
        const invoiceRef = ref()
        const makeInvoice = async (formEl: FormInstance | undefined) => {

            // console.log(policyData.value)
            Validations.is_reference_no_exist = false
            isSubmitted.value = true
            if (!formEl) return
            formEl.validate(async (valid, fields: any) => {
                // console.log(fields)
                if (valid) {
                    payment_warning.value.open(true)
                    // const payload = invoice.value;
                    const formData = new FormData();
                    // formData.append('invoice', invoice.value);

                    

                    for(const key in invoice.value) {
                        formData.set(key,invoice.value[key]?.toString() ?? '');
                    }
                   
                    const lead_id = router.currentRoute.value.params.lead_id.toString();
                    formData.set('customer_id', health.value.id ? health.value.id.toString() : '')
                    formData.set('policy_sales_agent_id', health.value.agent?.toString() ?? '0')
                    formData.set('lead_id', lead_id)
                    formData.set('invoice_doc', Receipt ?? '');
                    formData.set('cheque_doc', chequeReceipt ?? '');
                    formData.set('email', health.value.email ?? '');
                    formData.set('is_split_payment', invoice.value.is_split_payment ? '1' : '0')
                    formData.set('is_discount_given', invoice.value.is_discount_given ? '1' : '0')

                    
                    // formData.append('insurance_plan', invoice.value.insurance_plan ?? '')
                    // formData.append('policy_price', invoice.value.policy_price.toString() ?? '0')
                    // formData.append('invoice_amount', invoice.value.invoice_amount.toString() ?? '0')
                    // formData.append('sales_price', invoice.value.sales_price.toString() ?? '0')
                    // formData.append('basmah', invoice.value.basmah.toString() ?? '0')
                    // formData.append('discount_amount', invoice.value.discount_amount.toString() ?? '0')
                    // formData.append('client_classification', invoice.value.client_classification ?? '')
                    // formData.append('payment_type', invoice.value.payment_type ?? '')
                    // formData.append('pending_payment_type', invoice.value.pending_payment_type ?? '')
                    // formData.append('installments', invoice.value.installments ?? '')
                    // formData.append('order_description', invoice.value.order_description ?? '')
                    // formData.append('reference_no', invoice.value.reference_no ?? '')
                    // formData.append('payment_date', invoice.value.payment_date ?? '')
                    
                    // formData.append('language', invoice.value.language ?? 'en');
                    // formData.append('expiry_date_time', invoice.value.expiry_date_time ?? '');
                    const result = await Invoice.makeInvoice(formData);
                    // console.log(result); 
                    payment_warning.value.open(false)
                    isSubmitted.value = false
                    if (result.status == 200) {

                        
                        notification.success(
                            'Invoice',
                            'Invoice made successfully'
                        );

                        // await Invoice.makeInvoiceEvent({
                        //     driver_id: currentLead.value,
                        //     type: 'health'
                        // })
                        // goLists(health.value.id);

                        // window.open(result.data.data.file_path, '_blank');
                    } else {
                        if(result?.data?.data?.reference_no) {
                            
                            Validations.is_reference_no_exist = true
                            rule.customerDetails = 'reference_no'
                            setTimeout(() => {
                                formEl.validateField('reference_no')
                                invoiceRef.value.focus()
                            }, 200);
                        }
                            
                    }
                }
                else {
                    rule.customerDetails = Object.keys(fields)[0]
                    console.log('rules',rule.customerDetails)
                     if(invoiceRef.value) invoiceRef.value.focus();
                }
            })
        }

        onBeforeMount(() => {
            window.addEventListener("beforeunload", preventNav)
        })

        function preventNav(event) {
            if (!isSubmitted.value) return
            event.preventDefault()
            event.returnValue = ""
        }



        const inst = getCurrentInstance()


        onMounted(async () => { 
            setCurrentPageBreadcrumbs("Make Invoice ", [{ label: "Leads & Tasks", value: "CustomerLists" }]);
            const elLoadingS = ElLoading.service({
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0.1)',
            })

            await Quote.setGenerateQuotePolicies([]);

            await Etcs.getVat();
            await Invoice.setInvoice(invoiceDefault.value)
            // console.log('invoice',invoice.value)
            Customers.getLeadsByLeadId({
                id: parseInt(router.currentRoute.value.params.lead_id.toString())
            }).then(() => {
                // window.scrollTo(0, 750);
                const listRef:any = inst?.refs[`invoice_information`]
                if(listRef){
                    listRef.scrollIntoView({ behavior: "smooth" });
                }
            })
            elLoadingS.close()

            // console.log(health.value);
        })

        const goLists = (customer_id: any) => {
            router.replace({ name: 'CustomerDetails', params: { customer_id } })
        }



        const checkform = () => {
            const myForm: any = document.querySelectorAll('#last')
            console.log(myForm)
        }

        const currentLead = computed(() => {
            return router.currentRoute.value.params.lead_id
        })

        const redirectIt = () => {
            router.push({
                name: "CustomerDetails",
                params: { customer_id: health.value.id },
            });
        }

        return {
            payment_warning,
            hasPendingInvoice,
            redirectIt,
            invoiceModalRef,
            currentLead,
            store,
            clickLoading,
            checkform,
            top,
            middle,
            last,
            revalidate,
            loading,
            rule,
            rules,
            health,
            ruleFormRef,
            etc,
            goLists,
            makeInvoice,
            handleFileUpload,
            handleChequeFileUpload,
            invoice,
            openModal,
            isError,
            closeModal,
            invoiceRef
        }
    },
})
